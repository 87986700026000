import moment from "moment-timezone";
import { formatDate } from "./date";

const SERVER_URL = getServerUrl();
const SERVER_API_URL = `${SERVER_URL}/api`;
const JSON_CONTENT_TYPE = "application/json";

export const MAX_FILE_SIZE = 10485760;
export const MAX_FILE_SIZE_LABEL = "10MB";

export function promiseSetState(self, state, callback) {
    return new Promise((resolve) => {
        self.setState(state, resolve, callback);
    });
}

export function chunkRequests(parallelism, fn, arrayOfArgs) {
    if (!arrayOfArgs || !arrayOfArgs.length) {
        return Promise.resolve([]);
    }

    const results = [];

    let loadedSuccessfully = 0;

    markLoadingProgress(0);

    return chunk(arrayOfArgs, parallelism)
        .reduce(
            (promise, chunk) =>
                promise.then(() =>
                    Promise.all(
                        chunk.map((args) =>
                            fn.apply(this, args).then((result) => {
                                results.push(result);

                                markLoadingProgress(
                                    (++loadedSuccessfully * 100) / arrayOfArgs.length
                                );
                            })
                        )
                    )
                ),
            Promise.resolve()
        )
        .then(() => markLoadingProgress(100))
        .then(() => results);
}

export function chunk(array, size) {
    const chunkedArray = [];

    let index = 0;
    while (index < array.length) {
        chunkedArray.push(array.slice(index, size + index));

        index += size;
    }

    return chunkedArray;
}

function getServerUrl() {
    return window.location.origin;
}

export function getBuildInfo(background) {
    return httpGet(`version`, {}, background);
}

export function getCurrentUser(background) {
    return httpGet("users/me", {}, background);
}

export function getUsers(q, page, size, orderBy, order, background) {
    return httpGet("users", { q, page, size, sort: createSortParam(orderBy, order) }, background);
}

export function getUserPhoto() {
    return fetch(`${SERVER_API_URL}/users/me/photo`)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response has problem");
            }
            return response.text();
        })
        .catch((error) => {
            console.error("Error loading image:", error);
            return null;
        });
}

export function getUsersWithContracts(
    q,
    state,
    domain,
    contract,
    page,
    size,
    orderBy,
    order,
    background
) {
    return httpGet(
        "users/with-contract-for-administration",
        {
            q,
            state,
            contract,
            domain,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function getImpersonationUsers(
    q,
    state,
    domain,
    contract,
    page,
    size,
    orderBy,
    order,
    background
) {
    return httpGet(
        "users/with-contract",
        {
            q,
            state,
            contract,
            domain,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function getPresence(date, userName, location, background) {
    return httpGet(
        `presence/${date.year()}/${date.format("MM")}`,
        { userName, location },
        background
    );
}

export function setPresence(
    dateFrom,
    dateTo,
    allowedDays,
    excludeHolidays,
    excludeAbsences,
    presenceDetails,
    userName,
    location,
    background
) {
    return httpPut(
        `presence?${getUrlParamsAsString({ userName, location: location.key })}`,
        {
            DateFrom: formatDate(dateFrom),
            DateTo: formatDate(dateTo),
            AllowedDays: allowedDays.join(""),
            ExcludeHolidays: excludeHolidays,
            ExcludeAbsences: excludeAbsences,
            Presence: presenceDetails,
        },
        background
    );
}

export function rejectWorklogs(idsWithVersion, background) {
    return httpPut("worklogs/overtime/reject", idsWithVersion, background);
}

export function acceptWorklogs(idsWithVersion, background) {
    return httpPut("worklogs/overtime/accept", idsWithVersion, background);
}

export function getWorklogs(dateFrom, dateTo, userName, background) {
    return httpGet("worklogs", { dateFrom, dateTo, userName }, background);
}

export function getOvertimeWorklogs(dateFrom, dateTo, userName, background) {
    return httpGet("worklogs/overtime", { dateFrom, dateTo, userName }, background);
}

export function getOvertimeWorklogsPage(page, size, orderBy, order, background) {
    return httpGet(
        "worklogs/overtime/page",
        { page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getCountOfPendingOvertimes(background) {
    return httpGet("/worklogs/overtime/countAll", {}.background);
}

export function getWorklogsSum(dateFrom, dateTo, userName, background) {
    return httpGet("worklogs/sum", { dateFrom, dateTo, userName }, background);
}

export function getLoggedUserPrivateWorklogLock(background) {
    return httpGet("lock", {}, background);
}

export function getUserPrivateWorklogLock(userId, background) {
    return httpGet(`lock/users/${userId}`, {}, background);
}

export function getUsersWithPrivateLocks(q, page, size, orderBy, order, background) {
    return httpGet(
        "lock/users",
        { q, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getDomainLocks(q, page, size, orderBy, order, background) {
    return httpGet(
        "lock/domains",
        { q, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getGlobalWorklogLock(background) {
    return httpGet("lock/global", {}, background);
}

export function setGlobalWorklogLock(dateTo, background) {
    return httpPost("lock/global", { DateTo: dateTo }, background);
}

export function setPrivateWorklogLock(userId, dateTo, background) {
    return httpPost(
        "lock/users",
        {
            DateTo: dateTo,
            UserId: userId,
        },
        background
    );
}

export function setDomainWorklogLock(domain, dateTo, background) {
    return httpPost(
        "lock/domains",
        {
            DateTo: dateTo,
            Domain: domain,
        },
        background
    );
}

export function deleteUserWorklogLock(userId, background) {
    return httpDelete(`lock/users/${userId}`, {}, background);
}

export function deleteDomainWorklogLock(domain, background) {
    return httpDelete(`lock/domain?domain=${domain}`, {}, background);
}

export function addWorklogs(
    {
        taskId,
        dateFrom,
        dateTo,
        days,
        excludeHolidays,
        excludeAbsences,
        timeSpent,
        comment,
        presenceDetails,
        userName,
        location,
    },
    background
) {
    return httpPost(
        `worklogs?${getUrlParamsAsString({ userName, location: location.key })}`,
        {
            TaskId: taskId,
            DateFrom: formatDate(dateFrom),
            DateTo: formatDate(dateTo),
            AllowedDays: days.join(""),
            ExcludeHolidays: excludeHolidays,
            ExcludeAbsences: excludeAbsences,
            TimeSpent: timeSpent,
            Comment: comment,
            Presence: presenceDetails,
        },
        background
    );
}

export function addOvertimeWorklogs(
    {
        taskId,
        dateFrom,
        dateTo,
        days,
        excludeHolidays,
        excludeAbsences,
        timeSpent,
        comment,
        presenceDetails,
        userName,
        location,
    },
    background
) {
    return httpPost(
        `worklogs/overtime?${getUrlParamsAsString({ userName, location: location.key })}`,
        {
            TaskId: taskId,
            DateFrom: formatDate(dateFrom),
            DateTo: formatDate(dateTo),
            AllowedDays: days.join(""),
            ExcludeHolidays: excludeHolidays,
            ExcludeAbsences: excludeAbsences,
            TimeSpent: timeSpent,
            Comment: comment,
            Presence: presenceDetails,
        },
        background
    );
}

export function getEnovaImportTemplateUrl() {
    return `${SERVER_API_URL}/import/xlsx/enova/template`;
}

export function importXlsxEnova(file, background) {
    return httpPostMultipartFormData("import/xlsx/enova", { file }, background);
}

export function getGroupsPage(q, page, size, orderBy, order, background) {
    return httpGet(
        `groups/page`,
        {
            q,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function isUserInGroup(groupId, userId) {
    return httpGet(`groups/${userId}/exist/${groupId}`);
}

export function addGroup(name) {
    return httpPost(`groups/add/group`, { name });
}

export function addGroupToProject(groupId, projectId) {
    return httpPut(`groups/add/${projectId}/${groupId}`);
}

export function addGroupToTask(groupId, projectId, taskId) {
    return httpPut(`groups/add/task?groupId=${groupId}&taskId=${taskId}`);
}

export function addUserToGroup(groupId, userId) {
    return httpPost(`groups/add/user`, { groupId, userId });
}

export function deleteUsersFromGroup(groupId, usersId) {
    return httpDelete(`groups/delete/users?groupId=${groupId}`, { usersId: usersId });
}

export function deleteUserFromGroup(groupId, userId) {
    return httpDelete(`groups/delete/user`, { groupId, userId });
}

export function deleteGroup(groupId) {
    return httpDelete(`groups/delete/${groupId}`);
}

export function getUsersPageFromGroup(groupId, q, page, size, orderBy, order, background) {
    return httpGet(
        `groups/users/page/${groupId}`,
        { q, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function editGroup(groupId, name) {
    return httpPut(`groups/group/edit/${groupId}/${name}`);
}

export function deleteWorklog({ worklogId, userName }, background) {
    return httpDelete(
        `worklogs/${worklogId}?${getUrlParamsAsString({ userName })}`,
        {},
        background
    );
}

export function deleteWorklogs({ worklogIds, userName }, background) {
    return httpDelete(
        `worklogs?${getUrlParamsAsString({ userName })}`,
        { WorklogsList: worklogIds.map((worklogId) => ({ WorklogId: worklogId })) },
        background
    );
}

export function deleteOvertimeWorklogs(worklogs, background) {
    return httpDelete(`worklogs/overtime`, worklogs, background);
}

export function updateWorklog(
    { worklogId, taskId, start, timeSpent, comment, userName },
    background
) {
    return httpPut(
        `worklogs/${worklogId}?${getUrlParamsAsString({ userName })}`,
        {
            Id: worklogId,
            TaskId: taskId,
            Started: formatDate(start),
            TimeSpent: timeSpent,
            Comment: comment,
        },
        background
    );
}

export function updateOvertimeWorklog(
    { worklogId, taskId, start, timeSpent, comment, userName, status, version },
    background
) {
    return httpPut(
        `worklogs/overtime/${worklogId}?${getUrlParamsAsString({
            userName,
        })}`,
        {
            Id: worklogId,
            TaskId: taskId,
            Started: formatDate(start),
            TimeSpent: timeSpent,
            Comment: comment,
            OvertimeWorklogStatus: status,
            Version: version,
        },
        background
    );
}

export function deleteOvertimeWorklog(worklogId, version, background) {
    return httpDelete(
        `worklogs/overtime/${worklogId}`,
        { id: worklogId, version: version },
        background
    );
}

export function getWorklogImportTemplateUrl() {
    return `${SERVER_API_URL}/import/xlsx/worklogs/template`;
}

export function importXlsxWorklogs(file, background) {
    return httpPostMultipartFormData("import/xlsx/worklogs", { file }, background);
}

export function getProjects(query, accessLevel, email, page, size, orderBy, order, background) {
    return httpGet(
        "projects",
        { query, accessLevel, email, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getProject(id, background) {
    return httpGet(`projects/${id}`, {}, background);
}

export function getTasks(projectId, q, state, userName, page, size, orderBy, order, background) {
    if (!projectId) {
        return Promise.reject();
    }

    if (!q) {
        q = "";
    }

    return httpGet(
        `projects/${projectId}/tasks`,
        {
            q,
            userName,
            state,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function getTask(projectId, taskId, background) {
    return httpGet(`projects/${projectId}/tasks/${taskId}`, {}, background);
}

export function createTask(
    projectId,
    taskName,
    taskSummary,
    taskState,
    taskVisibleForAll,
    background
) {
    return httpPost(
        `projects/${encodeURIComponent(projectId)}/tasks`,
        {
            Name: taskName,
            Summary: taskSummary,
            State: taskState,
            VisibleForAll: taskVisibleForAll,
        },
        background
    );
}

export function updateTask(
    projectId,
    taskId,
    taskName,
    taskSummary,
    taskState,
    taskVisibleForAll,
    background
) {
    return httpPost(
        `projects/${projectId}/tasks/${taskId}`,
        {
            Name: taskName,
            Summary: taskSummary,
            State: taskState,
            VisibleForAll: taskVisibleForAll,
        },
        background
    );
}

export function removeTaskFromProject(projectId, taskId, background) {
    return httpDelete(
        `projects/${encodeURIComponent(projectId)}/tasks/${encodeURIComponent(taskId)}`,
        {},
        background
    );
}

export function getPublicProjectsAndTasks(q, page, size, orderBy, order, background) {
    return httpGet(
        "projects/union/tasks/public",
        { q, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getUsersFromTask(taskId, q, page, size, orderBy, order, background) {
    return httpGet(
        "user-task/users",
        {
            taskId,
            q,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function getUsersWithTasks(projectId, q, page, size, orderBy, order, background) {
    return httpGet(
        "user-task/users-with-tasks",
        {
            projectId,
            q,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function getUserTaskCount(projectId, background) {
    return httpGet("user-task/count", { projectId }, background);
}

export function existsUserInTask(taskId, userId, background) {
    return httpGet("user-task/exists", { taskId, userId }, background);
}

export function addUserToTask(taskId, userId, background) {
    return httpPost("user-task", { taskId, userId }, background);
}

export function removeUserFromTask(taskId, userId, background) {
    return httpDelete("user-task", { taskId, userId }, background);
}

export function createProject(params) {
    return httpPost("projects", params);
}

export function updateProject(projectId, params) {
    return httpPut(`projects/${projectId}`, params);
}

export function getUsersFromProject(
    projectId,
    q,
    accessLevel,
    page,
    size,
    orderBy,
    order,
    background
) {
    return httpGet(
        "user-project/users",
        {
            projectId,
            q,
            accessLevel,
            page,
            size,
            sort: createSortParam(orderBy, order),
        },
        background
    );
}

export function existsUserInProject(projectId, userId, background) {
    return httpGet("user-project/exists", { projectId, userId }, background);
}

export function addUserToProject(projectId, userId, accessLevel, background) {
    return httpPost("user-project", { projectId, userId, accessLevel }, background);
}

export function editUserInProject(projectId, userId, accessLevel, background) {
    return httpPut("user-project", { projectId, userId, accessLevel }, background);
}

export function removeUserFromProject(projectId, userId, background) {
    return httpDelete("user-project", { projectId, userId }, background);
}

export function addProjectAccessRequests(projectIds, background) {
    return httpPost("access-requests/projects", { projectIds }, background);
}

export function addTaskAccessRequests(taskIds, background) {
    return httpPost("access-requests/tasks", { taskIds }, background);
}

export function getAccessRequests(
    q,
    status,
    requesterId,
    resolverId,
    page,
    size,
    orderBy,
    order,
    background
) {
    return httpGet(
        "access-requests",
        { q, status, requesterId, resolverId, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function acceptProjectAccessRequests(ids, background) {
    return httpPost("access-requests/projects/accept", { ids }, background);
}

export function rejectProjectAccessRequests(ids, background) {
    return httpPost("access-requests/projects/reject", { ids }, background);
}

export function acceptTaskAccessRequests(ids, background) {
    return httpPost("access-requests/tasks/accept", { ids }, background);
}

export function rejectTaskAccessRequests(ids, background) {
    return httpPost("access-requests/tasks/reject", { ids }, background);
}

export function getCountAccessRequest(background) {
    return httpGet("access-requests/countAll", {}, background);
}

export function getLoginUrl() {
    const origin = window.location.origin;
    if (origin === "http://localhost:3000") {
        return `http://localhost:8080/oauth2/authorization/azure`;
    }

    return `${origin}/oauth2/authorization/azure`;
}

export function logout(background) {
    if (!background) {
        markLoading();
    }

    return fetch(`${SERVER_API_URL}/logout`, {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        headers: {
            Accept: "application/json",
        },
    })
        .catch((e) => {
            if (!background) {
                markLoaded();
            }

            throw e;
        })
        .then((arg) => {
            if (!background) {
                markLoaded();
            }

            return arg;
        });
}

export function getAdvancedReport(data, background) {
    return httpGet("advanced-report", { fromView: data.viewName }, background);
}

export function getAdvancedReportParameterized(data, background) {
    return httpPost("advanced-report/generateReport", data, background);
}

export function getAdvancedReportExportUrl(data) {
    return `${SERVER_API_URL}/advanced-report/report.xlsx?${getUrlParamsAsString({
        fromView: data.viewName,
    })}`;
}

export function getAdvancedReportParameterizedExportUrl(params) {
    getReportFileUrl("advanced-report/get/report.xlsx", params, "report.xlsx");
}

export function getAdvancedReportNames(background) {
    return httpGet("advanced-report/getNames", {}, background);
}

export function getAdvancedReportParams(id, background) {
    return httpGet(`advanced-report/getParameters/${id}`, {}, background);
}

export function writeUsersIntoSpreadsheet(userId, projectId, groupId, role, background) {
    if (!background) {
        markLoading();
    }

    const options = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": JSON_CONTENT_TYPE,
        },
    };
    const FileSaver = require("file-saver");

    return fetch(
        `${SERVER_API_URL}/advanced-report/users.xlsx?userId=${userId}&groupId=${groupId}&projectId=${projectId}&role=${role}`,
        options
    )
        .then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    if (!background) {
                        markLoaded();
                    }
                    FileSaver.saveAs(blob, "Users.xlsx");
                });
            } else {
                response
                    .text()
                    .then((text) => {
                        if (!background) {
                            markLoaded();
                        }
                        throw new RemoteError(
                            response.status,
                            "Error occurred while attempting to download report",
                            text
                        );
                    })
                    .catch((error) => {
                        if (!background) {
                            markLoaded();
                        }
                        throw onError(error, false);
                    });
            }
        })
        .catch((error) => {
            if (!background) {
                markLoaded();
            }
            throw onError(error, false);
        });
}

export function getUsersWithEmptyWorklogReport(params, background) {
    return httpGet("users/reports/withEmptyWorklogs", params, background);
}

export function getUserProjectsDetails(userId, role, groupId, projectId, background) {
    return httpGet("users/project/details", { userId, role, groupId, projectId }, background);
}

export function getUsersWithEmptyWorklogReportExportUrl(params) {
    return `${SERVER_API_URL}/users/reports/withEmptyWorklogs.xlsx?${getUrlParamsAsString(params)}`;
}

export function getProjectsReport(params, background) {
    return httpPost("worklogs/reports/projectsView", params, background);
}

export function saveProjectsReport(name, payload, background) {
    return httpPost("worklogs/reports/projects", { name, ...payload }, background);
}

export function getProjectsReportExportUrl(params) {
    getReportFileUrl("worklogs/reports/projects.xlsx", params, "projectsReport.xlsx");
}

export function getProjectsReportPdfUrl(params) {
    getReportFileUrl("worklogs/reports/projects.pdf", params, "projectsReport.pdf");
}

export function getReportFileUrl(url, payload, fileName, background = false) {
    const options = {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Time-Zone": moment.tz.guess() || "UTC",
        },
        body: processPayload(payload, "application/json"),
    };
    const FileSaver = require("file-saver");

    if (!background) {
        markLoading();
    }

    console.log(payload, options);

    return fetch(`${SERVER_API_URL}/${url}`, options)
        .then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    FileSaver.saveAs(blob, `${fileName}`);
                    markLoaded();
                });
            } else {
                response
                    .json()
                    .then((error) => {
                        throw new RemoteError(response.status, error.message);
                    })
                    .catch((error) => {
                        markLoaded();
                        throw onError(error, false);
                    });
            }
        })
        .catch((error) => {
            markLoaded();
            throw onError(error, false);
        });
}

export function getProjectsWithTasks(
    { query, email, accessLevel, page, size, orderBy, order },
    background
) {
    return httpGet(
        "worklogs/withTask/reports/getAll/tasksForProject",
        { query, accessLevel, email, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getProjectReportPlusPreview(params, background) {
    return httpPost("worklogs/withTask/reports/projectsView", params, background);
}

export function saveProjectReportPlusToSql(name, payload, background) {
    return httpPost("worklogs/withTask/reports/projects", { name, ...payload }, background);
}

export function getProjectReportPlusXlsxExportUrl(params) {
    getReportFileUrl("/worklogs/withTask/reports/projects.xlsx", params, "projectsReportPlus.xlsx");
}

export function getProjectsReportPlusPdfUrl(params) {
    getReportFileUrl("worklogs/withTask/reports/projects.pdf", params, "projectsReportPlus.pdf");
}

export function saveCustomerReportExport(params, file, reportExtension = "xlsx", background) {
    const options = {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "X-Time-Zone": moment.tz.guess() || "UTC",
        },
        body: processPayload({ file }, "multipart/form-data"),
    };
    const FileSaver = require("file-saver");

    if (!background) {
        markLoading();
    }

    return fetch(
        `${SERVER_API_URL}/customer-reports/projects.${reportExtension}?${getUrlParamsAsString(
            params
        )}`,
        options
    )
        .then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    FileSaver.saveAs(blob, `report.${reportExtension}`);
                    markLoaded();
                });
            } else {
                response
                    .json()
                    .then((error) => {
                        if (!background) {
                            markLoaded();
                        }
                        throw new RemoteError(response.status, error.message);
                    })
                    .catch((error) => {
                        if (!background) {
                            markLoaded();
                        }
                        throw onError(error, false);
                    });
            }
        })
        .catch((error) => {
            if (!background) {
                markLoaded();
            }
            throw onError(error, false);
        });
}

export function getUsersReportPreviewUrl(params) {
    return `${SERVER_API_URL}/presencereport?${getUrlParamsAsString(params)}`;
}

export function getUsersOvertimeReportUrl(params) {
    return `${SERVER_API_URL}/presencereport/overtime/export.xlsx?${getUrlParamsAsString(params)}`;
}

export function getUsersReportUrl(params) {
    return `${SERVER_API_URL}/presencereport/export.pdf?${getUrlParamsAsString(params)}`;
}

export function getUsersReportZipUrl(params) {
    return `${SERVER_API_URL}/presencereport/export.zip?${getUrlParamsAsString(params)}`;
}

export function getUsersReportXlsxUrl(params) {
    return `${SERVER_API_URL}/presencereport/export.xlsx?${getUrlParamsAsString(params)}`;
}

export function updateUser(userId, params) {
    return httpPut(`users/${userId}`, params);
}

export function getNotifications() {
    return httpGet("scheduled-notifications");
}

export function createNotification(period, location, time, day, background) {
    return httpPost(
        `scheduled-notifications?period=${period}&location=${location?.key}&time=${time}&dayOfWeek=${day}`,
        {},
        background
    );
}

export function deleteNotification(id) {
    return httpDelete(`scheduled-notifications/${id}`);
}

export function getNotificationTemplates() {
    return httpGet("notifications");
}

export function updateNotificationTemplate(id, params, background) {
    return httpPut(`notifications/${id}`, params, background);
}

export function getUserNotifications() {
    return httpGet("user-notification");
}

export function createNotificationBlock(notificationId) {
    return httpPost("user-notification", { notificationId }, false);
}

export function deleteNotificationBlock(blockId) {
    return httpDelete(`user-notification/${blockId}`);
}

export function getLocations(deleted = false, background = true) {
    return httpGet("locations/all", { deleted }, background);
}

export function getLocation(locationName) {
    return httpGet(`locations?locationName=${locationName}`);
}

export function createLocation(
    key,
    title,
    domain,
    shortcut,
    buttons,
    weekHours,
    workingDays,
    background
) {
    return httpPost(
        `locations/add/location`,
        {
            key,
            title,
            domain,
            shortcut,
            weekHours,
            workingDays,
            buttons,
        },
        background
    );
}

export function updateLocation(id, params, background) {
    return httpPut(`locations/location/edit/${id}`, params, background);
}

export function deleteLocation(id) {
    return httpDelete(`locations/${id}`);
}

export function restoreLocation(id) {
    return httpPut(`locations/restore/${id}`);
}

export function getHolidays(from, to, locationKey, background) {
    return httpGet("holidays", { from, to, location: locationKey }, background);
}

export function createHoliday(holidayDate, holidayName, locationKey, background) {
    return httpPost(
        `holidays/add/holiday`,
        {
            holidayDate,
            holidayName,
            location: locationKey,
        },
        background
    );
}

//category
export function createFAQCategory(category) {
    return httpPost(`faq/category`, {
        name: category.name,
        description: category.description,
        permissions: category.permissions,
        category_priority: category.category_priority,
    });
}

export function editFAQCategory(category) {
    return httpPut(`faq/category/${category.id}`, category);
}

export function getFAQCategories(background) {
    return httpGet("faq/category/getAll", {}, background);
}

export function getFAQCategoryPermissions(background) {
    return httpGet("faq/category/permissionCategory", {}, background);
}

export function getFAQCategory(categoryId) {
    return httpGet(`faq/category/${categoryId}`);
}

export function deleteFAQCategory(categoryId) {
    return httpDelete(`faq/category/${categoryId}`);
}

export function updateFAQCategoryPositions(params, background) {
    return httpPost("faq/category/positions", params, background);
}

//question
export function createFAQQuestion(question, background) {
    return httpPost(
        `faq/question`,
        {
            question: question.question,
            answer: question.answer,
            category_id: question.category.id,
            question_priority: question.question_priority,
        },
        background
    );
}

export function editFAQQuestion(id, params, background) {
    return httpPut(`faq/question/${id}`, params, background);
}

export function getFAQQuestion(id) {
    return httpGet(`faq/question/${id}`);
}

export function getFAQCategoryQuestions(categoryId, { q, page, size, orderBy, order }, background) {
    return httpGet(
        `faq/question/getBy/category/${categoryId}`,
        { q, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function getFAQ({ q, page, size, orderBy, order }, background) {
    return httpGet(
        "faq/question/getAll",
        { q, page, size, sort: createSortParam(orderBy, order) },
        background
    );
}

export function removeFAQQuestion(id) {
    return httpDelete(`faq/question/${id}`);
}

export function updateFAQPositions(params, background) {
    return httpPost("faq/question/positions", params, background);
}

// User configuration
export function getUserConfiguration(background) {
    return httpGet(`user-configuration`, null, background);
}

export function createUserConfiguration(data, background) {
    return httpPost(`user-configuration`, data, background);
}

// Templates Manager
export function getEventTemplates(background) {
    return httpGet(`theme/all`, null, background);
}

export function updateEventTemplate(params, background) {
    return httpPost(`theme`, params, background);
}

export function deleteEventTemplate(id, background) {
    return httpDelete(`theme/${id}`, null, background);
}

function httpGet(url, params, background) {
    return getFromUrl(`${SERVER_API_URL}/${url}`, params, background);
}

function getFromUrl(url, params, background) {
    if (!background) {
        markLoading();
    }

    return fetch(`${url}?${getUrlParamsAsString(params)}`, {
        credentials: "include",
        headers: {
            Accept: "application/json",
        },
    })
        .catch((e) => {
            console.error(e);

            throw onError(
                new RemoteError(0, "Connection error, please try again later"),
                background
            );
        })
        .then((response) => parseResponse(response, background))
        .catch((e) => {
            if (!background) {
                markLoaded();
            }

            throw e;
        })
        .then((arg) => {
            if (!background) {
                markLoaded();
            }

            return arg;
        });
}

function getUrlParamsAsString(urlParams) {
    if (!urlParams) {
        return "";
    }

    return Object.entries(urlParams)
        .map((entry) => {
            if (entry[1] === undefined) {
                return "";
            }

            if (entry[1] === null) {
                return encodeURIComponent(entry[0]);
            }

            return `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`;
        })
        .join("&");
}

export function createSortParam(orderBy, order) {
    return orderBy ? `${orderBy},${order}` : undefined;
}

function httpPost(url, payload, background) {
    return httpRequest("POST", url, payload, "application/json", background);
}

function httpPostMultipartFormData(url, payload, background) {
    return httpRequest("POST", url, payload, "multipart/form-data", background);
}

function httpPut(url, payload, background) {
    return httpRequest("PUT", url, payload, "application/json", background);
}

function httpDelete(url, payload, background) {
    return httpRequest("DELETE", url, payload, "application/json", background);
}

function httpRequest(method, url, payload, contentType, background) {
    if (!background) {
        markLoading();
    }

    const options = {
        method: method,
        credentials: "include",
        cache: "no-cache",
        headers: {
            Accept: "application/json",
            "Content-Type": contentType,
            "X-Time-Zone": moment.tz.guess() || "UTC",
        },
        body: processPayload(payload, contentType),
    };

    if (contentType === "multipart/form-data") {
        delete options.headers["Content-Type"];
    }

    return fetch(`${SERVER_API_URL}/${url}`, options)
        .catch((e) => {
            console.error(e);

            throw onError(
                new RemoteError(
                    0,
                    "Server is unavailable, please check your internet connection and try again."
                ),
                background
            );
        })
        .then((response) => parseResponse(response, background))
        .catch((e) => {
            if (!background) {
                markLoaded();
            }

            throw e;
        })
        .then((arg) => {
            if (!background) {
                markLoaded();
            }

            return arg;
        });
}

function processPayload(payload, contentType) {
    if (contentType === "application/json") {
        return JSON.stringify(payload ? payload : {});
    } else if (contentType === "multipart/form-data") {
        const formData = new FormData();

        for (const field in payload) {
            if (payload.hasOwnProperty(field)) {
                formData.append(field, payload[field]);
            }
        }

        return formData;
    }
}

function parseResponse(response, background) {
    if (response.ok) {
        return response
            .text()
            .then((text) => {
                if (!text) {
                    return null;
                }

                try {
                    return JSON.parse(text);
                } catch (e) {
                    console.error(e);

                    throw new RemoteError(
                        response.status,
                        "Connection error, please try again later",
                        text
                    );
                }
            })
            .catch((error) => {
                throw onError(error, background);
            });
    }

    return response
        .text()
        .then((text) => {
            try {
                const json = JSON.parse(text);

                return new RemoteError(response.status, json.message || response.statusText, json);
            } catch (e) {
                console.error(e);

                return new RemoteError(
                    response.status,
                    "Connection error, please try again later",
                    text
                );
            }
        })
        .then((error) => {
            throw onError(error, background);
        });
}

class RemoteError extends Error {
    constructor(code, message, response) {
        super(message);

        this.name = "RemoteError";
        this.code = code;
        this.response = response;
    }
}

//
const errorListeners = [];

export function registerErrorListener(onError) {
    errorListeners.push(onError);

    return onError;
}

export function unregisterErrorListener(onError) {
    const index = errorListeners.indexOf(onError);
    if (index >= 0) {
        errorListeners.splice(index, 1);
    }

    return onError;
}

export function onError(error, background) {
    try {
        errorListeners.forEach((callback) => callback(error, background));
    } catch (e) {
        console.error(e);
    }

    return error;
}

//

let loading = false;
let loadingProgress = null;

let loadCounter = 0;
let loadingTimer = null;
const loadingDelay = 256;
const loadingListeners = [];

export function registerLoadListener(onLoadChange) {
    loadingListeners.push(onLoadChange);

    return onLoadChange;
}

export function unregisterLoadListener(onLoadChange) {
    const index = loadingListeners.indexOf(onLoadChange);
    if (index >= 0) {
        loadingListeners.splice(index, 1);
    }

    return onLoadChange;
}

export function markLoadingProgress(progress) {
    loadingProgress = Math.floor(progress);

    loadingListeners.forEach((callback) => callback(loading, getLoadingProgress()));
}

export function markLoading(arg) {
    try {
        ++loadCounter;

        loading = true;
        clearTimeout(loadingTimer);
        if (loadCounter > 0) {
            loadingListeners.forEach((callback) => callback(true, getLoadingProgress()));
        }
    } catch (e) {
        console.error(e);
    }

    return arg;
}

function getLoadingProgress() {
    if (loadingProgress === null || loadingProgress === undefined) {
        return null;
    }

    return Math.min(100, Math.max(0, loadingProgress));
}

export function markLoaded(arg) {
    try {
        loadCounter = Math.max(loadCounter - 1, 0);

        if (loadCounter === 0) {
            clearTimeout(loadingTimer);
            loadingTimer = setTimeout(() => {
                loading = false;
                loadingProgress = null;

                loadingListeners.forEach((callback) => callback(false, null));
            }, loadingDelay);
        }
    } catch (e) {
        console.error(e);
    }

    return arg;
}
